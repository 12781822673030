import { z } from 'zod';
export const BLOCKCHAIN_NETWORKS = [
    'eth-mainnet',
    // 'eth-goerli',
    // 'eth-sepolia',
    'opt-mainnet',
    'arb-mainnet',
    // 'arb-sepolia',
    'polygon-mainnet',
    // 'polygon-mumbai',
    // 'astar-mainnet',
    // 'polygonzkevm-mainnet',
    // 'polygonzkevm-testnet',
    'base-mainnet',
    // 'base-goerli',
    'sol-mainnet',
    'bnb-mainnet',
    'linea-mainnet',
    'avalanche-mainnet',
    'fantom-mainnet',
    'cronos-mainnet',
    'palm-mainnet',
    'gnosis-mainnet',
    'chiliz-mainnet',
    'moonbeam-mainnet',
    'polygonzkevm-mainnet',
    'zksync-mainnet',
    'ton-mainnet',
    'gunz-testnet',
];
// at some point we will need to split these for NFT as there are less chains that support nfts than tokens and we should have separate switch / selects
export const networkSchema = z.enum(BLOCKCHAIN_NETWORKS);
export const BLOCKCHAINS = {
    'eth-mainnet': {
        name: 'ethereum',
        chainId: 1,
    },
    'opt-mainnet': {
        name: 'optimism',
        chainId: 10,
    },
    'arb-mainnet': {
        name: 'arbitrum',
        chainId: 42161,
    },
    'polygon-mainnet': {
        name: 'polygon',
        chainId: 137,
    },
    'base-mainnet': {
        name: 'base',
        chainId: 8453,
    },
    'sol-mainnet': {
        name: 'solana',
        chainId: 101,
    },
    'bnb-mainnet': {
        name: 'binance smart chain',
        chainId: 56,
    },
    'linea-mainnet': {
        name: 'linea',
        chainId: 59144,
    },
    'avalanche-mainnet': {
        name: 'avalanche',
        chainId: 43114,
    },
    'fantom-mainnet': {
        name: 'fantom',
        chainId: 250,
    },
    'cronos-mainnet': {
        name: 'cronos',
        chainId: 25,
    },
    'palm-mainnet': {
        name: 'palm',
        chainId: 11297108109,
    },
    'gnosis-mainnet': {
        name: 'gnosis',
        chainId: 100,
    },
    'chiliz-mainnet': {
        name: 'chiliz',
        chainId: 88888,
    },
    'moonbeam-mainnet': {
        name: 'moonbeam',
        chainId: 1284,
    },
    'polygonzkevm-mainnet': {
        name: 'polygon zkEVM',
        chainId: 1101,
    },
    'zksync-mainnet': {
        name: 'zksync',
        chainId: 324,
    },
    'ton-mainnet': {
        name: 'ton',
        chainId: 1100,
    },
    'gunz-testnet': {
        name: 'gunz testnet',
        chainId: 49321,
    },
};
export const convertBlockchainNameToNetwork = (blockchain) => {
    if (blockchain === 'none' || !blockchain)
        return undefined;
    if (BLOCKCHAIN_NETWORKS.includes(blockchain))
        return blockchain;
    return Object.entries(BLOCKCHAINS).find(([_, { name }]) => blockchain === name)?.[0];
};
export const isBlockchainCaseSensitive = (blockchain) => {
    // modify this when adding another case sensitive blockchain
    return blockchain === 'sol-mainnet';
};
export const isBlockchainVerified = (blockchain) => {
    return !!convertBlockchainNameToNetwork(blockchain);
};
const convertedVerifiedAddresses = (verifiedAddresses) => {
    return Object.fromEntries(Object.entries(verifiedAddresses ?? {})
        .filter(([network]) => !!BLOCKCHAINS[network])
        .map(([network, address]) => [BLOCKCHAINS[network].name, address]));
};
const filterVerifiedAddresses = (addresses) => {
    return Object.fromEntries(Object.entries(addresses ?? {}).filter(([network, value]) => !isBlockchainVerified(network) && !!value));
};
export const reconcileAddresses = (addresses, verifiedAddresses, ethWallet) => {
    return {
        ...filterVerifiedAddresses(addresses),
        ...(ethWallet ? { 'eth-mainnet': ethWallet, ethereum: ethWallet } : {}),
        ...verifiedAddresses,
        ...convertedVerifiedAddresses(verifiedAddresses),
    };
};
export const NATIVE_TOKEN_ADDRESS = '0';
export const TOKENS_BY_CHAIN = {
    'eth-mainnet': [
        {
            contractAddress: NATIVE_TOKEN_ADDRESS,
            symbol: 'ETH',
            decimals: 18,
            logo: 'https://www.datocms-assets.com/105223/1693895608-ethereum-nav.svg',
            name: 'Ethereum',
        },
        {
            name: 'USDC',
            symbol: 'USDC',
            contractAddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
            decimals: 6,
            logo: 'https://static.alchemyapi.io/images/assets/3408.png',
        },
        {
            contractAddress: '0xB8c77482e45F1F44dE1745F52C74426C631bDD52',
            decimals: 18,
            logo: 'https://static.alchemyapi.io/images/assets/1839.png',
            name: 'Binance Coin',
            symbol: 'BNB',
        },
        {
            contractAddress: '0x6b175474e89094c44da98b954eedeac495271d0f',
            decimals: 6,
            logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png',
            name: 'Dai',
            symbol: 'DAI',
        },
        {
            contractAddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
            decimals: 6,
            logo: 'https://static.alchemyapi.io/images/assets/825.png',
            name: 'Tether USDt',
            symbol: 'USDT',
        },
        {
            contractAddress: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
            decimals: 8,
            logo: 'https://static.alchemyapi.io/images/assets/3717.png',
            name: 'Wrapped Bitcoin',
            symbol: 'WBTC',
        },
        {
            contractAddress: '0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE',
            decimals: 18,
            logo: 'https://static.alchemyapi.io/images/assets/5994.png',
            name: 'Shiba Inu',
            symbol: 'SHIB',
        },
    ],
    'opt-mainnet': [
        {
            contractAddress: NATIVE_TOKEN_ADDRESS,
            symbol: 'ETH',
            decimals: 18,
            logo: 'https://assets.coingecko.com/coins/images/279/standard/ethereum.png?1696501628',
            name: 'Ethereum',
        },
        {
            name: 'USDC',
            symbol: 'USDC',
            contractAddress: '0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85',
            decimals: 6,
            logo: 'https://static.alchemyapi.io/images/assets/3408.png',
        },
        {
            symbol: 'WBTC',
            name: 'Wrapped BTC',
            contractAddress: '0x68f180fcCe6836688e9084f035309E29Bf0A2095',
            decimals: 8,
            logo: 'https://static.alchemyapi.io/images/assets/3717.png',
        },
        {
            symbol: 'OP',
            name: 'Optimism',
            contractAddress: '0x4200000000000000000000000000000000000042',
            decimals: 18,
            logo: 'https://assets.coingecko.com/coins/images/25244/small/OP.jpeg?1650971767',
        },
    ],
    'arb-mainnet': [
        {
            contractAddress: NATIVE_TOKEN_ADDRESS,
            symbol: 'ETH',
            decimals: 18,
            logo: 'https://assets.coingecko.com/coins/images/279/standard/ethereum.png?1696501628',
            name: 'Ethereum',
        },
        {
            symbol: 'DAI',
            name: 'Dai',
            contractAddress: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
            decimals: 18,
            logo: 'https://assets.coingecko.com/coins/images/9956/standard/Badge_Dai.png?1696509996',
        },
        {
            symbol: 'ARB',
            name: 'Arbitrum',
            contractAddress: '0x912ce59144191c1204e64559fe8253a0e49e6548',
            decimals: 18,
            logo: 'https://assets.coingecko.com/coins/images/16547/standard/photo_2023-03-29_21.47.00.jpeg?1696516109',
        },
    ],
    'polygon-mainnet': [
        {
            contractAddress: NATIVE_TOKEN_ADDRESS,
            symbol: 'MATIC',
            decimals: 18,
            logo: 'https://assets.coingecko.com/coins/images/4713/standard/polygon.png?1698233745',
            name: 'Matic',
        },
        {
            name: 'USDC',
            symbol: 'USDC',
            contractAddress: '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359',
            decimals: 6,
            logo: 'https://static.alchemyapi.io/images/assets/3408.png',
        },
        {
            name: 'BNB',
            symbol: 'BNB',
            contractAddress: '0x3BA4c387f786bFEE076A58914F5Bd38d668B42c3',
            decimals: 18,
            logo: 'https://assets.coingecko.com/coins/images/825/standard/bnb-icon2_2x.png?1696501970',
        },
        {
            contractAddress: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
            decimals: 6,
            logo: 'https://assets.coingecko.com/coins/images/325/standard/Tether.png?1696501661',
            name: 'Tether',
            symbol: 'USDT',
        },
        {
            symbol: 'WETH',
            name: 'Wrapped Ether',
            contractAddress: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
            decimals: 18,
            logo: 'https://static.alchemyapi.io/images/assets/2396.png',
        },
    ],
    'base-mainnet': [
        {
            contractAddress: NATIVE_TOKEN_ADDRESS,
            symbol: 'ETH',
            decimals: 18,
            logo: 'https://assets.coingecko.com/coins/images/279/standard/ethereum.png?1696501628',
            name: 'Ethereum',
        },
        {
            name: 'USDC',
            symbol: 'USDC',
            contractAddress: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
            decimals: 6,
            logo: 'https://static.alchemyapi.io/images/assets/3408.png',
        },
        {
            symbol: 'DAI',
            name: 'Dai',
            contractAddress: '0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb',
            decimals: 18,
            logo: 'https://assets.coingecko.com/coins/images/9956/standard/Badge_Dai.png?1696509996',
        },
        {
            contractAddress: '0xB6fe221Fe9EeF5aBa221c348bA20A1Bf5e73624c',
            decimals: 18,
            logo: 'https://assets.coingecko.com/coins/images/20764/standard/reth.png?1696520159',
            symbol: 'Rocket Pool ETH',
            name: 'rETH',
        },
        {
            contractAddress: '0xB0fFa8000886e57F86dd5264b9582b2Ad87b2b91',
            decimals: 18,
            logo: 'https://assets.coingecko.com/coins/images/35087/standard/womrhole_logo_full_color_rgb_2000px_72ppi_fb766ac85a.png?1708688954',
            symbol: 'Wormhole',
            name: 'W',
        },
    ],
    'gunz-testnet': [
        {
            contractAddress: NATIVE_TOKEN_ADDRESS,
            symbol: 'GUN',
            decimals: 18,
            logo: 'https://storage.gunbygunz.com/gunz_logo_white_500.png',
            name: 'Gun',
        },
    ],
    'sol-mainnet': [
        {
            contractAddress: NATIVE_TOKEN_ADDRESS,
            symbol: 'SOL',
            decimals: 9,
            logo: 'https://assets.coingecko.com/coins/images/4128/standard/solana.png?1696504756',
            name: 'Solana',
        },
        {
            name: 'USDC',
            symbol: 'USDC',
            contractAddress: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
            decimals: 6,
            logo: 'https://assets.coingecko.com/coins/images/6319/standard/usdc.png?1696506694',
        },
        {
            contractAddress: 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
            decimals: 6,
            logo: 'https://assets.coingecko.com/coins/images/325/standard/Tether.png?1696501661',
            name: 'Tether',
            symbol: 'USDT',
        },
        {
            contractAddress: 'rndrizKT3MK1iimdxRdWabcF7Zg7AR5T4nud4EkHBof',
            decimals: 8,
            logo: 'https://assets.coingecko.com/coins/images/11636/standard/rndr.png?1696511529',
            name: 'Render',
            symbol: 'RNDR',
        },
        {
            symbol: 'RAY',
            decimals: 6,
            logo: 'https://assets.coingecko.com/coins/images/13928/standard/PSigc4ie_400x400.jpg?1696513668',
            name: 'Raydium',
            contractAddress: '4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R',
        },
    ],
    'bnb-mainnet': [
        {
            contractAddress: NATIVE_TOKEN_ADDRESS,
            symbol: 'BNB',
            decimals: 18,
            logo: 'https://assets.coingecko.com/coins/images/825/standard/bnb-icon2_2x.png?1696501970',
            name: 'BNB',
        },
        {
            symbol: 'USDT',
            decimals: 18,
            logo: 'https://assets.coingecko.com/coins/images/325/standard/Tether.png',
            name: 'Tether',
            contractAddress: '0x55d398326f99059ff775485246999027b3197955',
        },
        {
            symbol: 'USDC',
            decimals: 18,
            logo: 'https://assets.coingecko.com/coins/images/6319/standard/usdc.png?1696506694',
            name: 'USDC',
            contractAddress: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        },
        {
            symbol: 'DAI',
            decimals: 18,
            logo: 'https://assets.coingecko.com/coins/images/9956/standard/Badge_Dai.png',
            name: 'Dai',
            contractAddress: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
        },
        {
            symbol: 'LINK',
            decimals: 18,
            logo: 'https://assets.coingecko.com/coins/images/877/standard/chainlink-new-logo.png',
            name: 'ChainLink',
            contractAddress: '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
        },
        {
            symbol: 'UNI',
            decimals: 18,
            logo: 'https://assets.coingecko.com/coins/images/12504/standard/uni.jpg',
            name: 'Uniswap',
            contractAddress: '0xbf5140a22578168fd562dccf235e5d43a02ce9b1',
        },
        {
            symbol: 'AVAX',
            decimals: 18,
            logo: 'https://assets.coingecko.com/coins/images/12559/standard/Avalanche_Circle_RedWhite_Trans.png',
            name: 'Avalanche',
            contractAddress: '0x1ce0c2827e2ef14d5c4f29a091d735a204794041',
        },
        {
            symbol: 'DOT',
            decimals: 18,
            logo: 'https://assets.coingecko.com/coins/images/12171/standard/polkadot.png',
            name: 'Polkadot',
            contractAddress: '0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
        },
        {
            symbol: 'BCH',
            decimals: 18,
            logo: 'https://assets.coingecko.com/coins/images/780/standard/bitcoin-cash-circle.png',
            name: 'Bitcoin Cash Token',
            contractAddress: '0x8ff795a6f4d97e7887c79bea79aba5cc76444adf',
        },
    ],
    'linea-mainnet': [
        {
            symbol: 'ETH',
            decimals: 18,
            logo: 'https://www.datocms-assets.com/105223/1693895608-ethereum-nav.svg',
            name: 'Ethereum',
            contractAddress: NATIVE_TOKEN_ADDRESS,
        },
        {
            symbol: 'USDC',
            decimals: 18,
            logo: 'https://assets.coingecko.com/coins/images/6319/standard/usdc.png?1696506694',
            name: 'USDC',
            contractAddress: '0x176211869cA2b568f2A7D4EE941E073a821EE1ff',
        },
        {
            symbol: 'DAI',
            decimals: 18,
            logo: 'https://assets.coingecko.com/coins/images/9956/standard/Badge_Dai.png',
            name: 'Dai',
            contractAddress: '0x4AF15ec2A0BD43Db75dd04E62FAA3B8EF36b00d5',
        },
        {
            symbol: 'USDT',
            decimals: 18,
            logo: 'https://assets.coingecko.com/coins/images/325/standard/Tether.png',
            name: 'Tether',
            contractAddress: '0xA219439258ca9da29E9Cc4cE5596924745e12B93',
        },
    ],
    'avalanche-mainnet': [
        {
            symbol: 'AVAX',
            decimals: 18,
            logo: 'https://assets.coingecko.com/coins/images/12559/standard/Avalanche_Circle_RedWhite_Trans.png',
            name: 'Avalanche',
            contractAddress: NATIVE_TOKEN_ADDRESS,
        },
    ],
    'fantom-mainnet': [
        {
            symbol: 'FTM',
            decimals: 18,
            logo: 'https://assets.coingecko.com/coins/images/4001/standard/Fantom_round.png',
            name: 'Fantom',
            contractAddress: NATIVE_TOKEN_ADDRESS,
        },
    ],
    'cronos-mainnet': [
        {
            symbol: 'CRO',
            decimals: 18,
            logo: 'https://assets.coingecko.com/coins/images/7310/standard/cro_token_logo.png',
            name: 'Cronos',
            contractAddress: NATIVE_TOKEN_ADDRESS,
        },
    ],
    'palm-mainnet': [
        {
            symbol: 'PALM',
            decimals: 18,
            logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/28567.png',
            name: 'PaLM AI',
            contractAddress: NATIVE_TOKEN_ADDRESS,
        },
    ],
    'gnosis-mainnet': [
        {
            symbol: 'GNO',
            decimals: 18,
            logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1659.png',
            name: 'Gnosis',
            contractAddress: NATIVE_TOKEN_ADDRESS,
        },
    ],
    'chiliz-mainnet': [
        {
            symbol: 'CHZ',
            decimals: 18,
            logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4066.png',
            name: 'Chiliz',
            contractAddress: NATIVE_TOKEN_ADDRESS,
        },
    ],
    'moonbeam-mainnet': [
        {
            symbol: 'GLMR',
            decimals: 18,
            logo: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6836.png s',
            name: 'Moonbeam',
            contractAddress: NATIVE_TOKEN_ADDRESS,
        },
    ],
    'zksync-mainnet': [
        {
            symbol: 'ZKSync',
            decimals: 18,
            logo: 'https://assets.coingecko.com/coins/images/38043/standard/ZKTokenBlack.png?1718614502',
            name: 'ZKSync',
            contractAddress: NATIVE_TOKEN_ADDRESS,
        },
    ],
    'polygonzkevm-mainnet': [
        {
            symbol: 'ETH',
            decimals: 18,
            logo: 'https://www.datocms-assets.com/105223/1693895608-ethereum-nav.svg',
            name: 'Ethereum',
            contractAddress: NATIVE_TOKEN_ADDRESS,
        },
    ],
    'ton-mainnet': [
        {
            contractAddress: NATIVE_TOKEN_ADDRESS,
            symbol: 'TON',
            decimals: 9,
            logo: 'https://assets.coingecko.com/coins/images/17980/standard/ton_symbol.png',
            name: 'Toncoin',
        },
    ],
};
export const chainIdToNetwork = (chainId) => {
    return Object.entries(BLOCKCHAINS).find(([, { chainId: id }]) => id === chainId)?.[0];
};
